import React, { useState, useRef } from 'react'

const PasswordChallenge = ({ setIsValidPassword, password }) => {
  const [message, setMessage] = useState('')
  const passwordForm = useRef(null)

  const submitHandler = e => {
    const form = passwordForm.current
    if (password === form.password.value) {
      setIsValidPassword(true)
    } else {
      setMessage('Wrong password')
    }
    e.preventDefault()
  }
  return (
    <div className="col-md-6 mx-auto text-center">
      <form ref={passwordForm} style={{ padding: '40px' }} onSubmit={submitHandler}>
        <p>Please enter the password to access this content:</p>
        <input name="password" type="password" placeholder="Password" />
        <br />
        <br />
        <input type="submit" className="btn btn-primary fadeIn" value="Submit" />
        <br />
        <br />
        {message && (
          <div className="alert alert-warning" role="alert">
            {message}
          </div>
        )}
      </form>
    </div>
  )
}

export default PasswordChallenge
