import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RestrictedContent = ({ body, richTextOptions }) => {
  return (
    <div className="container post text-content">
      {documentToReactComponents(body.json, richTextOptions)}
    </div>
  )
}

export default RestrictedContent
