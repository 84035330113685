import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/post.css'
import HeroImage from '../components/HeroImage/HeroImage'
import richTextOptions from '../shared/richTextOptions'
import RestrictedContent from '../components/RestrictedContent'
import PasswordChallenge from '../components/PasswordChallenge'

const Restricted = ({ pageContext, location }) => {
  const [isValidPassword, setIsValidPassword] = useState(false)
  const path = location.pathname
  const { contentful_id: contentfulID, title, heroImage, body, password } = pageContext

  return (
    <Layout editPageId={contentfulID} path={path}>
      <SEO title={title} />
      <HeroImage src={heroImage.file.url} alt={title} title={title} isLargeImage />
      {isValidPassword ? (
        <RestrictedContent body={body} richTextOptions={richTextOptions} />
      ) : (
        <PasswordChallenge setIsValidPassword={setIsValidPassword} password={password} />
      )}
    </Layout>
  )
}

export default Restricted

Restricted.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}
